@use 'styles/variables' as *;

.appheader {
  display: flex;
  align-items: center;
  margin: 45px 0 45px 0;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding-bottom: 7px;

  .userpageheading {
    margin: 0;
  }

  .appheaderbutton {
    margin: 0 16px 0 10px;
  }
}

.instancegridpanel {
  margin: 32px 100px 10px 100px;
}

.instancegrid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.instancetile {
  height: max-content;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  background-color: white;
  transition: box-shadow 0.2s;
  border-radius: 5px;

  &.enabled {
    cursor: pointer;

    &:hover {
      box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
    }
  }

  &:focus {
    outline: none;
  }
}

.applogo {
  width: 100%;
  border-bottom: 1px solid rgb(211, 211, 211);
  border-radius: 5px 5px 0 0;
}

.appname {
  font-size: 1.2rem;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
}

.appsectionheader {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 16px;
  color: $ecs-blue-1;
}

.appsection {
  margin-bottom: 32px;
}

.namecontainer {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > i {
    color: grey;
    font-size: 1.2rem;
  }
}

/* expanded styles */

@media screen and (max-width: 820px) {
  .appheader {
    flex-direction: column;
    .appheaderbutton {
      margin-top: 10px;
    }
  }

  .instancegridpanel {
    margin: 32px 32px 10px 32px;
  }
}
