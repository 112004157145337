/* ecs logo classes */
.ecslogo {
  width: 120px;
  margin-left: 16px;
}

.marker {
  position: absolute;
  width: 150px;
  top: 32px;
  left: 32px;
}

.ecsimg {
  width: 100%;
}
