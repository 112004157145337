.featuretitle {
  font-weight: bold;
}

.features {
  margin-bottom: 32px;

  & li {
    margin-bottom: 16px;
  }
}
