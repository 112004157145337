.option {
  font-weight: normal;
  display: block;
  white-space: pre;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
}
