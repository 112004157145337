@use 'styles/variables' as *;

.errorpage {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .errorpanel {
    text-align: center;
    .erroricon {
      font-size: 4rem;
      color: $ecs-red-1;
    }

    .errormsg {
      font-size: 32px;
      color: $ecs-blue-1;
    }
  }
}
