.dropdown {
  position: relative;
  z-index: 8;
}

.dropdown_content {
  display: none;
  position: absolute;
  top: 35px;
  right: 0;
  min-width: 160px;
  border: 1px solid rgb(235, 235, 235);
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  box-shadow: 0 0 2px rgb(238, 238, 238);

  &.show {
    display: block;
  }

  & > a {
    display: block;
    padding: 8px 8px 8px 10px;
    color: grey;
    font-size: 0.9rem;
    border-bottom: 1px solid rgb(235, 235, 235);

    &:hover {
      text-decoration: none;
      color: #0056b3;
      background-color: rgb(240, 240, 255);
    }
  }
}

.dropicon {
  font-size: 0.8rem;
  margin-left: 8px;
}

.navlink {
  color: cornflowerblue;
  cursor: pointer;
  margin-left: 40px;
  font-size: 1.1rem;

  /* make it non selectable */
  user-select: none; /* Standard */

  &:hover {
    text-decoration: none;
    color: #0056b3;
  }
}

.usertext {
  color: grey;

  &:hover {
    color: #333;
  }
}

@media screen and (max-width: 850px) {
  .navlink {
    margin-left: 0;
  }
}
