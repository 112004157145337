.alertwindow {
  width: 100%;
  max-width: 100%;
  color: white;
  border-radius: 5px;
  padding: 5px 10px 5px 15px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 10px;
  text-align: left;
}

.alerticon {
  font-size: 1.1rem;
  margin-right: 10px;
}

.successwindow {
  @extend .alertwindow;
  background-color: green;
}

.errorwindow {
  @extend .alertwindow;
  background-color: orangered;
}
