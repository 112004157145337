@use 'styles/variables' as *;

body {
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  line-height: 1.5;
  overflow-x: hidden;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

html {
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.2;
}

/* Do not use webkit cancel button */
input[type='search']::-webkit-search-cancel-button {
  display: none;
}

/* Page Headings */
.pageheading {
  font-weight: bold;
  color: $ecs-blue-1;
  font-size: 2rem;
  cursor: default;
  display: block;
  margin-bottom: 32px;

  &.dashintro {
    display: block;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.userpageheading {
  position: relative;
  font-size: 1.8rem;
  margin: 32px 0 32px 0;
  text-align: left;
  color: $ecs-blue-1;
  width: max-content;
  cursor: default;
}

/* Page links in login page */
.pagelink {
  text-align: center;
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
}

/* make element non selectable */
.noselect {
  user-select: none; /* Standard */
}

/* SearchBox classes */
.searchbox {
  position: relative;
}

.searchinput {
  border-radius: 10px;
  border: none;
  padding-left: 10px;
  border: 1px solid grey;
  font-size: 1.2rem;
  width: 100%;
}

.searchinputcancel {
  position: absolute;
  right: 7%;
  top: 7px;
  color: grey;
  cursor: pointer;

  &:hover {
    color: #333;
  }
}

/* User Form */
.userform {
  padding: 12px 12px 8px 12px;
  border-radius: 10px;
  border: 1px solid rgb(224, 224, 224);
  margin-bottom: 16px;
  background-color: #ffffff;
}

/* Checkbox classes */
.checkbox {
  margin-top: 5px;
  height: 20px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid orangered;
  border-radius: 5px;
  color: orangered;
  cursor: pointer;
  font-size: 0.8rem;

  & > i {
    margin-top: 2px;
  }

  &.checked {
    border: 1px solid green;
    color: green;
  }
}

/* text link in editable field */
.textlink {
  color: cornflowerblue;
  cursor: pointer;

  &:hover {
    color: blue;
  }
}

/* App container */
.appcontainer {
  min-height: 100vh;
}

/* Button Row */
.btnrow {
  display: flex;

  & > button,
  & > a {
    flex: 1;
    margin: 10px 3px 5px 3px;
  }
}

/* Dialog content */
.dialogcontent {
  margin-bottom: 16px;
}

/* ECS dot */
.ecsdot {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: $ecs-red-1;
  margin-left: 3px;
  margin-bottom: -1px;
}

/* For changing cursor to pointer */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  cursor: pointer;
}

/* Gilroy font files */
@font-face {
  font-family: Gilroy;
  font-weight: 400;
  src: url(../assets/fonts/gilroy-medium.woff2);
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  font-weight: 700;
  src: url(../assets/fonts/gilroy-bold.woff2);
  font-display: swap;
}
