@use 'styles/variables' as *;

.linkasbutton {
  display: block;
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: 16px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: bold;
  transition: letter-spacing 0.5s;
  padding: 5px 5px 5px 8px;
  border: 1px solid #1486ff;
  font-family: inherit;
  line-height: inherit;
  cursor: pointer;
  text-decoration: none;
  text-align: center;

  & > i {
    margin: 0 5px 0 0;
  }

  background-color: white;
  color: #1486ff;

  &:hover {
    background-color: whitesmoke;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px cornflowerblue;
  }

  &[disabled] {
    background-color: #b3ceda;
    border-color: #e4e4e4;
    color: white;
  }
}
