.instancecontainer {
  width: 50%;
  margin: 0 auto;
}

.instancetileexpanded {
  width: 500px;
  box-shadow: 0 0 2px grey;
  position: relative;
  margin: 0 10px 10px 0;
  background-color: white;

  &:focus {
    outline: none;
    box-shadow: 0 0 2px black;
  }
}

.appnameexpanded {
  font-size: 1.3rem;
  flex: 1;
  word-break: break-word;
}

.applogoexpanded {
  width: 100%;
  height: 200px;
  border-bottom: 1px solid rgb(211, 211, 211);
  object-fit: cover;
}

.namecontainer {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > i {
    color: grey;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1400px) {
  .instancecontainer {
    width: 70%;
  }
}

@media screen and (max-width: 800px) {
  .instancecontainer {
    width: 80%;
  }
}

@media screen and (max-width: 620px) {
  .instancecontainer {
    width: 90%;
  }
}
