@use 'styles/variables' as *;

.navbarcontainer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  border-bottom: 1px solid rgb(235, 235, 235);
  background-color: white;
}

.navecslogo {
  width: 130px;
  padding: 10px;
}

.login {
  border-radius: 20px;
  padding: 4.8px;
  font-weight: bold;
  cursor: pointer;
}

.actionpanel {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navlink {
  color: cornflowerblue;
  cursor: pointer;
  margin-left: 40px;
  font-size: 1.1rem;

  /* make it non selectable */
  user-select: none; /* Standard */

  &:hover {
    text-decoration: none;
    color: $ecs-blue-1;
  }
}

.logoutbtn {
  text-decoration: none;
  background-color: $ecs-bs-1;
  border: none;
  cursor: pointer;
  display: block;
  padding: 10px 0 10px 10px;
  font-size: 0.9rem;
  width: 100%;
  text-align: left;
  color: white;
  font-weight: bold;
  border-radius: 0 0 5px 5px;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $ecs-bs-2;
  }
}

@media screen and (max-width: 850px) {
  .navbarcontainer {
    flex-direction: column;
    height: max-content;
  }

  .navlink {
    margin-left: 0;
  }

  .actionpanel {
    width: 100%;
    justify-content: space-between;
  }

  .navbarcontainer {
    padding-top: 0;
  }
}

@media screen and (max-width: 545px) {
  .actionpanel {
    flex-direction: column;
  }
}
