.fpcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.fppanel {
  text-align: center;
  width: 500px;
}

.fpinputpanel {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid grey;
  background-color: rgb(248, 248, 248);
}

.fploginpagelink {
  display: block;
  margin-top: 10px;
}

.fpsendlinkbtn {
  width: 80%;
}

@media screen and (max-width: 500px) {
  .fppanel {
    width: 90%;
  }
}
