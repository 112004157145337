@use 'styles/variables' as *;

.appfooter {
  display: flex;
  padding: 20px 30px 20px 30px;
  background-color: $ecs-blue-1;
  color: white;
  font-size: 0.8rem;
  margin-top: 48px;
}

.footerheading {
  font-weight: bold;
  color: white;
  font-size: 1.3rem;
  margin-bottom: 10px;
  text-decoration: underline $ecs-blue-3;
}
.productlinks,
.companydetails {
  margin-right: 48px;
}

.productlinks > a,
.contactdetails > a,
.companydetails > a {
  color: inherit;
  display: block;
}

.productlinks > a:hover,
.contactdetails > a:hover,
.companydetails > a:hover {
  text-decoration-color: $ecs-blue-3;
  color: inherit;
}

.companylink {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
