.rpcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.rppanel {
  text-align: center;
  width: 500px;
}

.rpinputpanel {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid grey;
  background-color: rgb(248, 248, 248);
}

.rploginpagelink {
  display: block;
  margin-top: 10px;
}

.rpsendlinkbtn {
  width: 80%;
}

@media screen and (max-width: 500px) {
  .rppanel {
    width: 90%;
  }
}
