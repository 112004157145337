@use 'styles/variables' as *;

.table_grid {
  box-shadow: 0 0 5px #001;
  padding: 5px;
  box-shadow: 0 0 5px grey;
  border-radius: 5px;
  transition: box-shadow 0.5s;
  margin-bottom: 32px;
}

.table_head {
  display: flex;
  justify-content: space-evenly;
}

.table_heading {
  flex-basis: 25%;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px rgb(185, 185, 185) solid;
  text-align: center;
  cursor: default;
  min-width: 150px;
}

.table_body {
  height: 400px;
  overflow-y: auto;
}

.table_row {
  padding: 5px;
  text-align: center;
  font-size: 1.1rem;
  display: flex;
  cursor: default;
  justify-content: space-evenly;

  &:hover {
    background-color: rgb(233, 233, 233);
  }
}

.table_cell {
  padding: 10px;
  flex-basis: 25%;
  min-width: 150px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_footer {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(233, 233, 233);

  & > button {
    width: 200px;
    margin: 0;
  }
}

// FIXME Is this even used?
.table_pagecounter {
  & > input {
    width: 100px;
    margin: 0 10px 0 10px;
    border-radius: 5px;
    border: 1px solid rgb(185, 185, 185);
    padding: 2px;
    text-align: center;
  }
}

.pagenumber {
  width: 48px;
  text-align: right;
}

.pagenumber--text_error {
  color: $ecs-red-1;
}
