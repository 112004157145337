$primary: rgb(111, 233, 111);

$ecs-blue-1: #24356d; /* ECS Blue */
$ecs-blue-2: #2c428d;
$ecs-blue-3: #98aed5; /* ECS Mid Blue */
$ecs-blue-4: #cbd6ea; /* ECS Light Blue */
$ecs-blue-5: #e9effa; /* ECS Light Blue */

$ecs-red-1: #bd2440; /* ECS Red */
$ecs-red-2: #da2849;
$ecs-red-3: #fa90a4;
$ecs-red-4: #ffd4dc;

/* Bootstrap colors for buttons */
$ecs-bs-1: #0275d8;
$ecs-bs-2: #2486db;
$ecs-bs-3: #5bc0de;
