.emptylistcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: grey;
}

.emptyicon {
  font-size: 50px;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: rgb(198, 218, 255);
  color: #538fff;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.line1 {
  font-size: 2rem;
}

.line2 {
}
