/* Search field for instances */
.appusersearcharea {
  position: relative;

  .searchicon {
    position: absolute;
    left: 7px;
    top: 9px;
    color: rgb(145, 145, 145);
    font-size: 0.9rem;
  }

  .closebutton {
    position: absolute;
    right: 7px;
    top: 7px;
    height: 16px;
    width: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
    cursor: pointer;

    border: none;
    background-color: white;

    &:hover,
    &:focus {
      outline: none;
      border: none;
      color: #333;
    }
  }
}

.appusersearchfield {
  outline: none;
  border: none;
  width: 350px;
  border: 1px solid rgb(202, 202, 202);
  border-radius: 5px;
  padding: 1px 2px 2px 5px;
  font-size: 1.1rem;
  padding-right: 28px;

  &:focus {
    border: 1px solid rgb(141, 141, 141);
  }
}

@-moz-document url-prefix() {
  .closebutton {
    top: 9px;
  }

  .searchicon {
    top: 5px;
  }
}
