$all-hover: rgb(241, 241, 241);
$none: #8fbcbb;
$none-hover: #eaffff;
$upending: #be7a04;
$upending-hover: #fff6e6;
$usuccess: #d85b7b;
$usuccess-hover: #ffe0e8;
$fail: #5e8ae7;
$fail-hover: #ebebff;
$success: #43b55f;
$success-hover: #e7ffe7;

.userpageheader {
  display: flex;
  align-items: center;
  margin: 45px 0 32px 0;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding-bottom: 7px;

  .userpageheadertitle {
    margin: 0;
  }

  & > button {
    margin: 0 16px 0 10px;
  }
}

.usergridpanel {
  margin: 32px 100px 10px 100px;
}

.usergrid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.usertile {
  height: max-content;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 10px;
  cursor: pointer;
  transition: box-shadow 0.2s;
  padding-bottom: 32px;
  position: relative;

  &:hover {
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
  }
}

.usertilename {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
}

.usertileusername {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: grey;
}

.usertilestatus {
  color: grey;
  font-size: 0.8rem;
  padding: 2px 4px 2px 4px;
  border-radius: 10px;
  min-width: 30px;
  font-weight: normal;
  display: flex;
  align-items: center;

  .filtericon {
    margin-right: 5px;
    font-size: 0.7rem;
  }

  &.filteroption {
    position: inherit;
    margin-right: 16px;
    cursor: pointer;
    padding: 2px 8px;

    /* Classes for each status */
    &.all {
      &:hover,
      &.filterselected {
        background-color: $all-hover;
      }
    }

    &.none {
      &:hover,
      &.filterselected {
        background-color: $none-hover;
      }
    }

    &.upending {
      &:hover,
      &.filterselected {
        background-color: $upending-hover;
      }
    }

    &.upending {
      &:hover,
      &.filterselected {
        background-color: $upending-hover;
      }
    }

    &.usuccess {
      &:hover,
      &.filterselected {
        background-color: $usuccess-hover;
      }
    }

    &.fail {
      &:hover,
      &.filterselected {
        background-color: $fail-hover;
      }
    }

    &.success {
      &:hover,
      &.filterselected {
        background-color: $success-hover;
      }
    }
  }

  &.filteroption:hover {
    background-color: #f1f1f1;
  }

  /* Classes for each status */
  &.none {
    color: $none;
    border-color: $none;
  }

  &.upending {
    color: $upending;
    border-color: $upending;
  }

  &.usuccess {
    color: $usuccess;
    border-color: $usuccess;
  }

  &.fail {
    color: $fail;
    border-color: $fail;
  }

  &.success {
    color: $success;
    border-color: $success;
  }
}

.usersactionpanel {
  display: flex;
}

.statusfilters {
  display: flex;
  align-items: center;
  margin: 0 16px 32px 0;
}

.usertilestatusdot {
  position: absolute;
  right: 15px;
  bottom: 15px;
  height: 9px;
  width: 18px;
  border-radius: 8px;

  &.nonedot {
    background-color: $none;
  }

  &.upendingdot {
    background-color: $upending;
  }

  &.faildot {
    background-color: $fail;
  }

  &.successdot {
    background-color: $success;
  }

  &.usuccessdot {
    background-color: $usuccess;
  }
}

@media screen and (max-width: 820px) {
  .userpageheader {
    flex-direction: column;
    & > button {
      margin-top: 10px;
    }
  }

  .usergridpanel {
    margin: 32px 32px 10px 32px;
  }
}

@media screen and (max-width: 1010px) {
  .statusfilters {
    flex-direction: column;
    align-items: flex-start;
    & > * {
      margin-bottom: 5px;
    }
  }
}
