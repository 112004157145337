@use 'styles/variables' as *;

.backbutton {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid $ecs-blue-1;
  color: $ecs-blue-1;
  font-size: 1.1rem;
  cursor: pointer;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: -64px;

  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;

  transition: color 0.3s, background-color 0.1s;

  &:hover,
  &:focus {
    background-color: $ecs-blue-1;
    color: white;
  }

  &:focus {
    outline: none;
  }
}
