@use 'styles/variables' as *;

/* ------------- Docs ------------ */

/* page title for docs pages */
.docspagetitle {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 24px;
  display: block;
  color: $ecs-blue-1;
}

/* Container class for docs */
.docs-container {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 2em;
}

/* Subheading in Docs pages */
.subheading {
  font-weight: bold;
  font-size: 1.3rem;
  display: block;
  margin-top: 32px;
  margin-bottom: 16px;
  color: $ecs-blue-1;
}

/* Title for sections in a page */
.docsectiontitle {
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: $ecs-blue-1;
}

/* Release Notes sections */
.releasenotesections {
  margin-bottom: 30px;
}

/* All ordered lists */
ol {
  margin-top: 10px;
}
ol li {
  margin-bottom: 5px;
}

/* Anchors inside list items */
ol li a {
  font-size: 1.1rem;
}

/* For all the links */
a {
  color: #007bff;
  word-break: break-word;
}
