.copypanel {
  display: inline;
}

.copyicon {
  margin-left: 16px;
  color: grey;
  cursor: pointer;

  &:hover {
    color: #333;
  }
}

.copyalert {
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  margin-left: 10px;
}
