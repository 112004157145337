@use 'styles/variables' as *;

.tabsbarcontainer {
  background: $ecs-blue-1;
  height: 3em;
  padding: 1em;
  padding-left: 2em;
  display: flex;
  align-items: center;
}

.link {
  color: $ecs-blue-4;
  margin-right: 3em;
  cursor: pointer;

  &.link {
    text-decoration: none;
  }

  &:hover {
    color: white;
  }
}

.chosen {
  font-weight: bold;
  color: white;
}

@media screen and (max-width: 580px) {
  .tabsbarcontainer {
    flex-direction: column;
    height: max-content;
    align-items: flex-start;
  }
}
