/* panel for password field */
.passwordpanel {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  align-items: flex-start;

  & > label {
    flex: 1;
  }
}
