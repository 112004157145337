.imagelabel {
  color: grey;
  font-size: 0.8rem;
  display: block;
  margin-top: -10px;
}

.captionedimage {
  text-align: center;
  cursor: pointer;
}

.imageshown {
  margin-bottom: 5px;
}
