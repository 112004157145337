.tabs {
  display: flex;
  flex-direction: column;
  margin: 10px;

  .tabbtns {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
}

.tab {
  flex: 1;
  text-align: center;
  font-size: 0.9rem;
  padding-bottom: 10px;
  cursor: pointer;
  border: none;
  background-color: white;
  border-left: 1px solid #00000000;
  border-right: 1px solid #00000000;
  border-bottom: 2px solid rgb(224, 224, 224);
  font-weight: bold;
  color: rgb(75, 78, 82);

  &:hover {
    border-left: 1px solid rgb(236, 243, 255);
    border-right: 1px solid rgb(236, 243, 255);
    border-bottom: 2px solid rgb(182, 197, 224);
    color: cornflowerblue;
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    cursor: default;
    color: rgb(75, 78, 82);
    &:hover {
      color: grey;
      border-bottom: 2px solid rgb(224, 224, 224);
    }
  }
}

.tabisselected {
  border-left: 1px solid rgb(236, 243, 255);
  border-right: 1px solid rgb(236, 243, 255);
  border-bottom: 2px solid cornflowerblue;
  color: cornflowerblue;
}

.tabcontent {
  margin: 16px;
}
