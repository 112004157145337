.ecsimg {
  max-width: 284px;
  margin-bottom: 24px;
}

.bufferview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.bufferlogo {
  width: 250px;
  margin-bottom: 16px;
}

.bufferbar {
  display: flex;
  justify-content: center;
  align-items: center;
}
