.signupcontainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.signuppanel {
  text-align: center;
  padding: 16px;
  border-radius: 10px;
  width: 40%;
  background-color: rgba(255, 255, 255, 0.3);
}

.name {
  display: flex;
  align-items: flex-start;

  & > label {
    flex: 1;
  }
}

.eulalink {
  margin-bottom: 32px;
}

.policylink {
  font-size: 0.8rem;
}

.signupbtn {
  width: 40%;
}

.signinlink {
  display: block;
  margin-bottom: 32px;
}

.recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

@media screen and (max-width: 1750px) {
  .signuppanel {
    width: 50%;
  }
}

@media screen and (max-width: 1100px) {
  .signuppanel {
    width: 70%;
  }
}

@media screen and (max-width: 700px) {
  .signuppanel {
    width: 80%;
  }
}

@media screen and (max-width: 620px) {
  .signuppanel {
    width: 80%;
  }

  .signupactionpanel {
    display: block;
  }
}
