.listItem {
  display: flex;
}

.listItemIndex {
  margin-right: 16px;
}

.orderedList {
  margin-left: 16px;
}

.itemChildren {
  & > span > p {
    margin-top: 0;
  }
}
