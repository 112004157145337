@use 'styles/variables' as *;

.mymodal {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);

  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  font-size: 1.6rem;
}

.modal_heading {
  @extend .heading;
  margin: 16px 16px 32px 16px;
}

.modal_main {
  /* To center the modal window */
  position: fixed;
  background: white;
  width: 50%;
  height: auto;
  padding: 12px 32px;
  border-radius: 10px;

  max-height: 90vh;
  overflow-y: auto;

  &:focus {
    outline: none;
    border: 1px solid cornflowerblue;
  }
}

.modal_close_btn {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  color: $ecs-red-1;
  font-size: 1.1rem;
  padding-left: 16px;
  background-color: white;
  border: none;

  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    color: $ecs-red-2;
  }
}

/* Dialog styles */
.modal_dialog {
  width: max-content;
  padding: 20px 32px;
}

.dialog_heading {
  @extend .heading;
  margin: 0 0 16px 0;
}
