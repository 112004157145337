.pnfcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.pnfpanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 grey;
  background-color: white;
}

.notfoundtext {
  font-weight: bold;
  color: rgb(104, 104, 104);
  font-size: 2rem;
  margin-bottom: 16px;
}

.notfoundnumber {
  font-size: 6rem;
  color: rgb(181, 219, 250);
  text-shadow: 0 0 2px grey;
}
