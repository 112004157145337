.cuppanel {
  width: 40%;
  text-align: center;
  margin: 0 auto;
}

.cupbtnpanel {
  margin-top: 16px;
  display: flex;

  & > button {
    margin: 5px;
    width: 100%;
  }
}

@media screen and (max-width: 1450px) {
  .cuppanel {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .cuppanel {
    width: 70%;
  }
}

@media screen and (max-width: 700px) {
  .cuppanel {
    width: 90%;
  }
}
