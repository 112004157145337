@use 'styles/variables' as *;

.rangesection {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.usagedetailsheader {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.usagedetailstimerange {
  font-weight: normal;
}

.quickfilters {
  font-size: 0.8rem;
  margin: 0 0 16px 14px;
  display: flex;
  flex-wrap: wrap;

  &.centered {
    justify-content: center;
  }
}

.quickfilteraction {
  cursor: pointer;
  display: inline;
  margin: 0 0 5px 8px;
  padding: 1px 4px 1px 4px;
  background-color: rgb(245, 245, 245);
  color: grey;
  border-radius: 5px;

  &:hover {
    color: $ecs-blue-1;
  }
}

.quickfilterselected {
  color: $ecs-blue-1;
  background-color: $ecs-blue-5;
}

.emptyrecords {
  padding: 32px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.incorrect-time-range {
  font-size: 0.9rem;
  color: $ecs-red-1;
  margin-left: 10px;
}

.modal--incorrect-time-range {
  color: $ecs-red-1;
  text-align: center;
  font-size: 0.9rem;
  margin-top: -8px;
}

@media screen and (max-width: 1048px) {
  .rangesection {
    flex-direction: column;
  }
}
