.userguidecontainer {
  display: flex;
  justify-content: center;
}

.userguidecontent {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  width: 100%;
}

.userguidepanel {
  text-align: center;
  width: 90%;
}

.navdrawer {
  text-align: left;
  padding-right: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  overflow-y: auto;
  align-self: flex-start;
}

.drawertitle {
  margin-top: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.contenttitle {
  margin-bottom: 0;
}

.contentcell {
  min-height: 32px;
  cursor: pointer;
  margin-bottom: 3px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: cornflowerblue;
  }
}

.arrowicons {
  font-size: 0.8rem;
  margin-right: 5px;
}

.linkstopages {
  text-decoration: none;
  color: #333;
}

.sectioncontent {
  min-height: 60vh;
  border-left: 1px solid rgb(199, 199, 199);
  text-align: left;
  margin: 0 10px 10px 0;
  padding: 0 0 16px 20px;
}

.docsectiontitle {
  color: #333;
  font-size: 1.7rem;
}

.sectionsubtitle {
  color: #333;
  font-size: 1.5rem;
}

.sectiontopic {
  color: #333;
  font-size: 1rem;
  font-weight: bold;
}

.sectionmaterial {
  margin-top: 20px;

  & ul li {
    margin-bottom: 10px;
  }

  & table,
  .sectionmaterial th,
  .sectionmaterial td {
    border: 1px solid black;
  }

  & th,
  td {
    padding: 10px;
  }

  & img {
    display: block;
    margin: 0 auto;
    padding: 20px;
    max-width: 90%;
  }
}

.sectionnote {
  font-size: 0.9rem;
  margin-top: 30px;
}

.releasenotesections {
  margin-bottom: 30px;
}
