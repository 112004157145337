/* cloud making */

.cloud {
  background-color: rgb(197, 194, 194);
  width: 200px;
  height: 250px;
  border-radius: 100px;
  position: absolute;
  transform: rotate(180deg);
  box-shadow: 0 0 10px rgb(197, 194, 194);
  opacity: 0.1;
  z-index: -2;

  &::after,
  &::before {
    content: '';
    background-color: rgb(197, 194, 194);
    width: 300px;
    height: 160px;
    border-radius: 150px;
    position: absolute;
    z-index: -3;
    box-shadow: 0 0 10px rgb(197, 194, 194);
    top: -5px;
  }

  &::after {
    left: -100px;
  }

  &::before {
    right: -80px;
  }

  &.one {
    left: 100px;
    bottom: 100px;
    transform: scale(0.7) rotate(180deg);
    animation: swooshcloudone 1s 1 forwards;
  }

  &.two {
    right: 100px;
    bottom: 80px;
    transform: rotate(180deg) rotateY(180deg) scale(0.8);
    opacity: 0.15;
    animation: swooshcloudtwo 1s 1 forwards;
  }

  &.three {
    right: 200px;
    top: 100px;
    transform: rotate(180deg) rotateY(180deg) scale(0.4);
    opacity: 0.1;
    animation: swooshcloudthree 1s 1 forwards;
  }

  &.four {
    left: 250px;
    top: 50px;
    transform: rotate(180deg) rotateY(180deg) scale(0.5);
    opacity: 0.15;
    animation: swooshcloudfour 1s 1 forwards;
  }

  &.five {
    left: 50px;
    bottom: 250px;
    transform: rotate(180deg) scale(0.3);
    display: none;
    animation: swooshcloudfive 1s 1 forwards;
  }
}

/* keyframes */

@keyframes swooshcloudone {
  0% {
    left: -100px;
  }
  100% {
    left: 100px;
  }
}

@keyframes swooshcloudtwo {
  0% {
    right: -100px;
  }
  100% {
    right: 100px;
  }
}

@keyframes swooshcloudthree {
  0% {
    right: -200px;
  }
  100% {
    right: 200px;
  }
}

@keyframes swooshcloudfour {
  0% {
    left: -250px;
  }
  100% {
    left: 250px;
  }
}

@keyframes swooshcloudfive {
  0% {
    left: -50px;
  }
  100% {
    left: 50px;
  }
}

@media screen and (max-width: 750px) {
  .cloud.three,
  .cloud.one {
    display: none;
  }

  .cloud.five {
    display: block;
  }
}
