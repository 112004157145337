@use 'styles/variables' as *;

/* buttons */

/* Primary Button */

.btn {
  display: block;
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: 16px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: bold;
  transition: letter-spacing 0.5s;
  padding: 5px 5px 5px 8px;
  font-family: inherit;
  line-height: inherit;
  cursor: pointer;

  & > i {
    margin: 0 5px 0 0;
  }
}

.btn-brand {
  @extend .btn;
  background-color: $ecs-bs-1;
  color: white;
  border: 1px solid $ecs-bs-2;

  &:hover {
    background-color: $ecs-bs-2;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px $ecs-bs-3;
  }

  &[disabled] {
    background-color: $ecs-bs-3;
    border-color: $ecs-bs-3;
  }
}

/* Secondary Button */
.btn-default {
  @extend .btn;
  background-color: white;
  color: $ecs-blue-1;
  border: 1px solid $ecs-blue-2;

  &:hover {
    background-color: whitesmoke;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px $ecs-blue-3;
  }

  &[disabled] {
    background-color: #b3ceda;
    border-color: #e4e4e4;
    color: white;
  }
}

/* Danger button */
.btn-red {
  @extend .btn-default;
  border-color: $ecs-red-1;
  color: $ecs-red-1;
}

/* Links inside buttons */
a.btn-brand,
a.btn-default {
  display: block;
  &:hover {
    text-decoration: none;
  }
}

/* Button spinner */
.btnspinner {
  .btnspinnericon {
    margin: 0 0 0 5px;
  }
}
