/* Date time picker classes */
.DateInput {
  width: 180px;
}

.SingleDatePickerInput {
}

.CustomDatePicker {
  display: inline-flex;
  margin: 0 10px;
}

.InvalidDateInput {
  .DateInput_input__focused,
  .DateInput_input {
    border-bottom-color: red;
  }
}

.TimePicker_container {
  position: relative;
}

.TimePicker {
  width: 110px;
  padding: 13px;
  border-radius: 0 2px 2px 0;
  border: 1px solid #dbdbdb;
  border-left: none;
  font-size: 1.1rem;
  color: #484848;
  &:focus {
    outline: 0;
    padding-bottom: 11px;
    border-bottom: 2px solid #008489;
  }
  &[disabled] {
    font-style: italic;
    background-color: #f2f2f2;
    color: #757585;
  }
}

.TimePicker_dropdown {
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid grey;
  border-radius: 5px;
  margin-top: 2px;
  z-index: 15;
  .TimePicker_dropdownItem {
    background-color: white;
    width: 107px;
    text-align: center;
    font-weight: normal;
    cursor: pointer;
    padding: 3px 0;
    border-bottom: 1px solid rgb(238, 238, 238);
    &:hover {
      color: cornflowerblue;
    }
  }
}
