.logincontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loginpanel {
  padding: 32px;
  width: 400px;
  border-radius: 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.loginlogo {
  height: 1em;
  margin-bottom: 32px;
}

.cominfo {
  font-size: 0.6rem;
  display: block;
  & > a {
    margin-left: 10px;
  }
}

.internalpanel {
  box-shadow: 0 0 2px 0 grey;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: white;
}

.loginbut {
  width: 70%;
}

.reason {
  position: absolute;
  bottom: 32px;
  padding: 8px 16px 8px 16px;
  color: white;
  background-color: #1e366d;
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%);
  max-width: 70%;
}

@media screen and (max-width: 900px) {
  .reason {
    font-size: 0.8rem;
  }
}
