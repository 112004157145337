.userprofilepanel {
  width: 40%;
  margin: 0 auto;
  margin-top: 32px;
}

.namerow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > label {
    flex: 1;
  }
}

.profilebtnrow {
  display: flex;
  align-items: center;
  justify-content: space-around;

  & > button,
  & > a {
    margin: 5px;
    margin-bottom: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 1600px) {
  .userprofilepanel {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .userprofilepanel {
    width: 70%;
  }
}

@media screen and (max-width: 700px) {
  .userprofilepanel {
    width: 90%;
  }
}
