.label {
  font-weight: bold;
  display: block;
  margin-bottom: 3px;
  text-align: left;
}

.field {
  display: block;
  margin: 0 16px 16px 16px;

  p {
    margin: 0;
    text-align: left;
    word-break: break-word;
  }
}

/* tooltip classes */
.tooltipicon {
  color: grey;
  margin-left: 5px;
  font-size: 0.8rem;

  &:hover {
    color: #333;
  }
}
